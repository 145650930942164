.react-pdf__Page {
    margin: 0 !important;
    padding: 0 !important;
}

.pdf-container {
    height: 80vh;
    overflow: auto;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdf-page {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
}

.pdf-page .react-pdf__Page__canvas {
    border: 1px solid #ccc;
}